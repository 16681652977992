const axios = require("axios").default;
axios.defaults.withCredentials = false;

export default class SessionManager {
  constructor(platformAddress) {
    this._platformAddress=platformAddress;
    this._APIUrl=this._platformAddress + "/" + SessionManager.apiVersion;
    this._userName=sessionStorage.getItem("sessionManager.userName");
    this._password=sessionStorage.getItem("sessionManager.password");
    this._sessionId=sessionStorage.getItem("sessionManager.sessionId");
    this._recServer=sessionStorage.getItem("sessionManager.recServer");
    this._dispatcher=sessionStorage.getItem("sessionManager.dispatcher");
    this._privileges=JSON.parse(sessionStorage.getItem("sessionManager.privileges"));
    this._token=sessionStorage.getItem("sessionManager.token");
    this._userId=sessionStorage.getItem("sessionManager.userId");
    if (this._userId!=null)
      this._userId=parseInt(this._userId);
    this._userType=sessionStorage.getItem("sessionManager.userType");
    if (this._userType!=null)
      this._userType=parseInt(this._userType);
    this._platformId=sessionStorage.getItem("sessionManager.platformId");
    this._default_user_privileges=JSON.parse(sessionStorage.getItem("sessionManager.default_user_privileges"));
    this._parameters=JSON.parse(sessionStorage.getItem("sessionManager.parameters"));
    this._clientItemList = JSON.parse(sessionStorage.getItem("sessionManager.clients"));
    this._operatorToken = JSON.parse(sessionStorage.getItem("sessionManager.operatorToken"));
    //this.clear();
    //console.log("CLient list",this._clientItemList);
    //console.log("SessionID",this._sessionId);
    //this._interval=setInterval(this.keepAlive.bind(this), 120000);
    //this._operatorInterval=setInterval(this.operatorKeepAlive.bind(this), 10000);
    console.log("SessionManager created");
    
  }
  
  static apiVersion = "restapi2";

  get userName() {
    if (this._userName==null)
      return "";
    return this._userName;
  }

  get password() {
    if (this._password==null)
      return "";
    return this._password;
  }

  set password(newPassword) {
    this._password=newPassword;
  }

  get sessionId() {
    if (this._sessionId==null)
      return "";
    return this._sessionId;
  }

  get platformId() {
    if (this._platformId==null)
      return "";
    return this._platformId;
  }

  get recServer() {
    if (this._recServer==null)
      return "";
    return this._recServer;
  }

  get dispatcher() {
    if (this._dispatcher==null)
      return "";
    return this._dispatcher;
  }

  get token() {
    if (this._token==null)
      return "";
    return this._token;
  }

  get userId() {
    if (this._userId==null)
      return -1;
    return this._userId;
  }

  get userType() {
    if (this._userType==null)
      return -1;
    return this._userType;
  }

  get privileges() {
    if (this._privileges==null)
      return 0;
    return this._privileges;
  }

  get default_user_privileges() {
    if (this._default_user_privileges==null)
      return {};
    return this._default_user_privileges;
  }

  get parameters() {
    if (this._parameters==null)
      return {};
    return this._parameters;
  }

  get clients() {
    if (this._clientItemList == null)
      return [];
    return this._clientItemList;
  }

  get operatorToken() {
    if (this._operatorToken==null)
      return "";
    return this._operatorToken;
  }

  clear() {
    this.clearUserInfo();
    //console.log("Clear");
    //sessionStorage.clear();

  }

  clearOperatorInfo(){
    clearInterval(this._operatorInterval);
    console.log("ClearOperatorInfo");
    this._clientItemList = null;
    sessionStorage.removeItem("sessionManager.clients");
    sessionStorage.removeItem("sessionManager.operatorToken"); 
  }

  clearUserInfo() {
    clearInterval(this._interval);
    this._userName=null;
    this._password=null;
    this._sessionId=null;
    this._recServer=null;
    this._dispatcher=null;
    this._privileges=null;
    this._token=null;
    this._userId=null;
    this._userType=null;
    this._platformId=null;
    this._default_user_privileges=null;
    this._parameters=null;
    sessionStorage.removeItem("sessionManager.userName");
    sessionStorage.removeItem("sessionManager.password");
    sessionStorage.removeItem("sessionManager.sessionId");
    sessionStorage.removeItem("sessionManager.recServer");
    sessionStorage.removeItem("sessionManager.dispatcher");
    sessionStorage.removeItem("sessionManager.privileges");

    sessionStorage.removeItem("sessionManager.token");
    sessionStorage.removeItem("sessionManager.userId");
    sessionStorage.removeItem("sessionManager.userType");
    sessionStorage.removeItem("sessionManager.platformId");
    sessionStorage.removeItem("sessionManager.default_user_privileges");
    sessionStorage.removeItem("sessionManager.parameters");
  }

  keepAlive() {
    if (this._sessionId==null) return;
    console.log("Session keepalive");
    const url = "https://" + this._recServer + "/" + SessionManager.apiVersion + "/config/?q=" + this._sessionId;
    this.sendAPIRequest(url,"GET");
  }

  async operatorKeepAlive() {
    if (this._operatorToken==null) return;
    console.log("Operator keepalive",this._operatorToken);
    //const url = "https://" + this._recServer + "/" + SessionManager.apiVersion + "/config/?q=" + this._sessionId;
    //this.sendAPIRequest(url,"GET");
    try {
      await this.loginex("","",this._operatorToken);
    }
    catch (error) {
      console.log(error);
    }

  }

//#region ----------------------- LOGIN -------------------------

  isLoggedIn() {
    
    if (this._sessionId==null) { 
      //console.log("Not logged 1");
      return false;
    }
    return true;
  }

  isLoggedInAsOperator() {

    if (this._clientItemList == null) {
      //console.log("Not logged as Operator");
      return false;
    }
    return true;
  }


  async login(email,password) {
    const data = {
      email: email,
      password: password,
    }
    const url = this._APIUrl + "/login";
    try {
      var response=await this.sendAPIRequest(url,"POST",data);
      console.log (response);
      this._userName=email;
      this._password=password;
      this._sessionId=response.userInfo.sessionId;
      this._recServer=response.userInfo.recServer;
      if (response.userInfo.dispatcher=="")
        this._dispatcher=response.userInfo.recServer + ":8080";  
      else
        this._dispatcher=response.userInfo.dispatcher;
      //this._privileges=response.userInfo.privileges;
      this._token=response.userInfo.token;
      this._userId=response.userInfo.userId;
      this._userType=response.userInfo.userType;
      this._platformId=response.userInfo.platformId;

      sessionStorage.setItem("sessionManager.userName",this._userName);
      sessionStorage.setItem("sessionManager.password",this._password);
      sessionStorage.setItem("sessionManager.sessionId",this._sessionId);
      this.setPrivileges(response.userInfo.userType,response.userInfo.privileges);
      sessionStorage.setItem("sessionManager.recServer",this._recServer);
      sessionStorage.setItem("sessionManager.dispatcher",this._dispatcher);
      sessionStorage.setItem("sessionManager.privileges",JSON.stringify(this._privileges));

      sessionStorage.setItem("sessionManager.token",this._token);
      sessionStorage.setItem("sessionManager.userId",this._userId.toString());
      sessionStorage.setItem("sessionManager.userType",this._userType.toString());
      sessionStorage.setItem("sessionManager.platformId",this._platformId);
//      sessionStorage.setItem("sessionManager.default_user_privileges",this._default_user_privileges.toString()));
//      sessionStorage.setItem("sessionManager.parameters",JSON.stringify(this._parameters));
      if (this._interval) clearInterval(this._interval);
      this._interval=setInterval(this.keepAlive.bind(this), 300000);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }      
  }

  async loginex(email, password, token) {

    let data = {};
    if (email !== "" && password !== "") {
      this._clientItemList = null;
      data = {
        email: email,
        password: password,
      }
    }
    if (token !== "") {
      data = {
        accessToken: token,
      }
    }
    const url = this._APIUrl + "/loginex";
    //console.log ("LoginEx",url,data);
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      //console.log(response);
      this._userName = email;
      this._password = password;
      if (response.userInfo !== undefined) {
        this._sessionId = response.userInfo.sessionId;
        this._recServer = response.userInfo.recServer;
        if (response.userInfo.dispatcher == "")
          this._dispatcher = response.userInfo.recServer + ":8080";
        else
          this._dispatcher = response.userInfo.dispatcher;
        //this._privileges=response.userInfo.privileges;
        this._token = response.userInfo.token;
        this._userId = response.userInfo.userId;
        this._userType = response.userInfo.userType;
        this._platformId = response.userInfo.platformId;

        sessionStorage.setItem("sessionManager.userName", this._userName);
        sessionStorage.setItem("sessionManager.password", this._password);
        sessionStorage.setItem("sessionManager.sessionId", this._sessionId);
        this.setPrivileges(response.userInfo.userType, response.userInfo.privileges);
        sessionStorage.setItem("sessionManager.recServer", this._recServer);
        sessionStorage.setItem("sessionManager.dispatcher", this._dispatcher);
        sessionStorage.setItem("sessionManager.privileges", JSON.stringify(this._privileges));

        sessionStorage.setItem("sessionManager.token", this._token);
        sessionStorage.setItem("sessionManager.userId", this._userId.toString());
        sessionStorage.setItem("sessionManager.userType", this._userType.toString());
        sessionStorage.setItem("sessionManager.platformId", this._platformId);
        //      sessionStorage.setItem("sessionManager.default_user_privileges",this._default_user_privileges.toString()));
        //      sessionStorage.setItem("sessionManager.parameters",JSON.stringify(this._parameters));
        if (this._interval) clearInterval(this._interval);
        this._interval=setInterval(this.keepAlive.bind(this), 300000);
      }
      if (response.clients !== undefined) {
        this._operatorToken=response.accessToken;
        //console.log("Operator token",this._operatorToken);
        this._clientItemList = [...response.clients];
        sessionStorage.setItem("sessionManager.clients", JSON.stringify(this._clientItemList));
        sessionStorage.setItem("sessionManager.operatorToken", JSON.stringify(this._operatorToken));
        if (this._operatorInterval) clearInterval(this._operatorInterval);
        this._operatorInterval=setInterval(this.operatorKeepAlive.bind(this), 1500000);
      }
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async logout() {
    this.clear();
  }

  async getConfig() {
    const url = "https://" + this._recServer + "/" + SessionManager.apiVersion + "/config/?q=" + this._sessionId;
    //const url = this._APIUrl + "/config/?q=" + this._sessionId;
    try {
      var response=await this.sendAPIRequest(url,"GET");
      console.log(response);
      this._default_user_privileges = {};
      for (let key in response.default_user_privileges) {
        this._default_user_privileges[key] = Boolean(
          Number(response.default_user_privileges[key])
        );
      }

      this._parameters = {};
      for (let key in response.parameters) {
        this._parameters[key] = Boolean(Number(response.parameters[key]));
      }  
      sessionStorage.setItem("sessionManager.default_user_privileges",JSON.stringify(this._default_user_privileges));
      sessionStorage.setItem("sessionManager.parameters",JSON.stringify(this._parameters));
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }      
  }

  setPrivileges(userType, privcode) {
    const onDemandRec = 1;
    const ptz = 2;
    const addCameras = 4;
    //const editCameras = 8;
    const deleteCameras = 16;
    const deleteRecordings = 32;
    const activation = 64;
    const showRecordings = 128;
    const exportRecordings = 256;
    const editTasks = 512;
    const editGroups = 1024;
    this._privileges = {
      onDemandRec: false,
      ptz: false,
      addCameras: false,
      editCameras: false,
      deleteCameras: false,
      activation: false,
      showRecordings: false,
      exportRecordings: false,
      editTasks: false,
      editGroups: false,
      viewCameras: true,
      editUsers: true,
    };
    console.log("set privileges", userType, privcode);
    this._privileges["onDemandRec"] = Boolean(privcode & onDemandRec);
    this._privileges["ptz"] = Boolean(privcode & ptz);
    this._privileges["addCameras"] = Boolean(privcode & addCameras);
    //now editCamera == addCameras = Modificar Camaras, in future it can be a separate privilege
    //privileges["editCameras"] = Boolean(privcode & editCameras);
    this._privileges["editCameras"] = Boolean(privcode & addCameras)
    this._privileges["deleteCameras"] = Boolean(privcode & deleteCameras);
    this._privileges["deleteRecordings"] = Boolean(privcode & deleteRecordings);
    this._privileges["activation"] = Boolean(privcode & activation);
    this._privileges["showRecordings"] = Boolean(privcode & showRecordings);
    this._privileges["exportRecordings"] = Boolean(privcode & exportRecordings);
    this._privileges["editTasks"] = Boolean(privcode & editTasks);
    this._privileges["editGroups"] = Boolean(privcode & editGroups);
    if (userType) {
      this._privileges["editUsers"] = false;
    }
    console.log("privileges", this._privileges);
  }


//#region ----------------------- API REQUEST -------------------------


  async sendAPIRequest(url,method="GET",data=null) {
    var func;
    var params;
    if (method=="GET") {
      func=axios.get;
    }
    else if (method=="POST") {
      func=axios.post;
    }
    else if (method=="PUT") {
      func=axios.put;
    }
    else if (method=="DELETE") {
      func=axios.delete;
    }

    if (data==null) {
      params=[url];
    }
    else {
      params=[url,data];
    }

    try {
      var response=await func(...params);
      //console.log(response);
      if (response.status == 200 && response.data.result >= 0) {
        return(response.data);
      } else {          
        throw(response.data);
      }

    }
    catch (error) {
      if (error.result===undefined) {
        throw ({
          result: -1,
          error: error
        });
      }
      else
        throw error;
    }
  }

//#endregion

}
